<template>
  <a-layout>
    <app-sidebar />
    <a-layout class="position-relative">
      <mobile-menu />
      <app-header />
      <app-content />
    </a-layout>
  </a-layout>
</template>
<script>
import { roles } from 'src/config/roles-config';
import { defineComponent, onMounted, ref, watch } from 'vue';
import { mapGetters, useStore } from 'vuex';
import Content from './ContentV2.vue';
import Header from './HeaderV2.vue';
import MobileMenu from './MobileMenu.vue';
import Sidebar from './SidebarV2.vue';

export default defineComponent({
  components: {
    AppSidebar: Sidebar,
    AppHeader: Header,
    AppContent: Content,
    MobileMenu,
  },
  setup() {
    const store = useStore();
    // const timer = ref(null);
    const isTimeout = ref(false);

    watch(isTimeout, (value) => {
      if (value) store.dispatch('userLogout');
    });

    onMounted(async () => {
      let redirectUrl = localStorage.getItem('redirectUrl');
      if (redirectUrl) {
        localStorage.removeItem('redirectUrl');
        window.location.href = redirectUrl;
      }

      store.dispatch('setFeatureSubscription', []);
      await store.dispatch('getAllFeatureSubscriptions');
    });
    // const setUpTimer = () => {
    //   timer.value = new AutoLogout({
    //     timeout: 600,
    //     onTimeout: () => {
    //       isTimeout.value = true;
    //     },
    //     onExpired: () => {
    //       isTimeout.value = true;
    //     },
    //   });
    // };

    // const resetTimer = () => {
    //   if (timer.value) timer.value.cleanUp();
    // };

    // onBeforeMount(setUpTimer);
    // onBeforeUnmount(resetTimer);

    return { roles };
  },
  computed: { ...mapGetters(['role']) },
});
</script>
