<template>
  <div>
    <a-modal
      title="Verify OTP - Email"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      :mask-closable="false"
      @ok="handleOk"
      @cancel="$emit('close', true)"
      okText="Verify"
      cancelText="Close"
      class="otp-authentication-modal"
    >
      <div class="otp-instruction">
        <p>Enter the 6-digit OTP received on your given email.</p>
      </div>
      <OTPInput ref="otpInputComponent" @update-otp-value="updateOtpValue" />

      <div class="OTPExpire" v-if="disableResendButton">
        <p v-if="minutes > 0">
          OTP code will expire in {{ minutes }} minutes {{ seconds }} seconds.
        </p>
        <p v-else-if="seconds > 0">
          OTP code will expire in {{ seconds }} seconds.
        </p>
        <p v-else>OTP expired.</p>
      </div>
      <div class="buttons-s mt-3 px-5" v-else>
        <a-button
          type="primary"
          @click="handleResendOtp"
          :disabled="this.disableResendButton"
          size="large"
          html-type="submit"
          class="btn-block"
        >
          <span>Resend OTP</span>
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { ref, defineComponent } from 'vue';
import { mapActions } from 'vuex';
import OTPInput from './OTPInput.vue';
import { useToast } from 'vue-toastification';
import AuthService from '@/services/auth';
export default defineComponent({
  components: {
    OTPInput,
  },
  data() {
    return {
      otpValue: '',
      disableResendButton: true,
      timeToResendOtp: 900,
      timerCount: 900,
      minutes: 15,
      seconds: 0,
      otpSentMessage:
        'We have just sent a 6-digit OTP to your email address. Please check your inbox and use the provided OTP for verification.',
      timerInterval: null,
    };
  },
  emits: ['close'],
  setup() {
    const modalText = ref('Content of the modal');
    const visible = ref(true);
    const confirmLoading = ref(false);
    const showModal = () => {
      visible.value = true;
    };

    const toast = useToast();

    return {
      modalText,
      visible,
      confirmLoading,
      showModal,
      toast,
    };
  },
  async mounted() {
    this.startTimer();
    await AuthService.sendOTP();
    this.toast.success(this.otpSentMessage);
  },
  methods: {
    ...mapActions(['resendOTP', 'verifyOTP']),
    startTimer() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }
      this.timerInterval = setInterval(() => {
        if (this.timerCount > 0) {
          this.timerCount--;
          this.minutes = Math.floor(this.timerCount / 60);
          this.seconds = this.timerCount % 60;
        } else {
          this.disableResendButton = false;
        }
      }, 1000);
    },
    async handleResendOtp() {
      this.timerCount = this.timeToResendOtp;
      this.$refs.otpInputComponent.clearInput();
      this.startTimer();
      await this.resendOTP();
      this.disableResendButton = true;
    },
    updateOtpValue(otpValue) {
      this.otpValue = otpValue;
    },
    async handleOk() {
      this.confirmLoading = true;
      await this.verifyOTP({ OTP: this.otpValue });
      this.confirmLoading = false;
    },
  },
  beforeUnmount() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  },
});
</script>

<style>
.OTPExpire {
  text-align: center;
}

.otp-instruction {
  text-align: center;
  margin-bottom: 20px;
}

.buttons-s .ant-btn {
  border-radius: 5px;
}
</style>
