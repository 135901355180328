<template>
  <a-layout-header class="d-flex align-items-center shadow-sm p-0">
    <a id="download-link" href="" download="file-exe" />

    <menu-unfold-outlined
      v-if="getSidebarState"
      class="trigger"
      @click="toggleNavMenu()"
    />

    <menu-fold-outlined v-else class="trigger" @click="toggleNavMenu()" />
    <a-typography-title
      v-if="getSidebarState || state.isMobileScreen"
      class="m-0"
      :level="5"
    >
      {{ currentRoute[0] }}
    </a-typography-title>
    <installer-button
      v-if="isOrgUser"
      class="d-none d-sm-none d-md-inline-block"
    />
    <installer-button
      v-if="isOrgUser"
      class="d-none d-sm-none d-md-inline-block"
      :diagnostic-tool="true"
    />
    <div class="mr-auto" />
    <a-menu
      v-if="isOrgUser"
      :selected-keys="currentRoute"
      mode="horizontal"
      class="d-flex justify-content-end align-items-center mr-3"
      :style="{ lineHeight: '52px', width: '45%' }"
    >
      <a-menu-item
        :key="fundamentals"
        @click="$router.push({ name: fundamentals })"
      >
        Fundamentals
      </a-menu-item>

      <a-menu-item
        :key="docsRouteName"
        @click="$router.push({ name: docsRouteName })"
      >
        Documentation
      </a-menu-item>

      <a-menu-item key="installer" class="d-md-none">
        <installer-button v-if="isOrgUser" />
      </a-menu-item>
      <a-menu-item key="diagnosticTool" class="d-md-none">
        <installer-button v-if="isOrgUser" :diagnostic-tool="true" />
      </a-menu-item>
    </a-menu>

    <a-dropdown
      v-if="
        host.includes('.test.retrocausal.tech') ||
        host == 'test.retrocausal.tech' ||
        host.includes('dev.retrocausal.tech') ||
        host === 'localhost:8080'
      "
    >
      <template #overlay>
        <a-menu id="enviroment-change-select" :selected-keys="[selectedURL]">
          <a-menu-item
            v-for="(label, url) in hostURLOptions"
            :id="'env-item-' + label"
            :key="label"
            @click="handleChangeURL(url)"
          >
            {{ label }}
          </a-menu-item>
        </a-menu>
      </template>
      <a-button id="selected-environment" class="mr-4">
        {{ selectedURL }}
        <DownOutlined />
      </a-button>
    </a-dropdown>

    <a-tag
      v-if="!['null', null, ''].includes(organization)"
      style="font-size: 0.9em"
    >
      <template #icon>
        <i class="bi bi-building d-inline-flex" />
      </template>
      {{ organization }}
    </a-tag>

    <a-dropdown>
      <template #overlay>
        <a-menu :selected-keys="currentRoute">
          <a-menu-item
            :key="profileRouteName"
            @click="$router.push({ name: profileRouteName })"
          >
            <UserOutlined class="mr-2" />
            {{ user }}
            <span v-if="role === roles.user" class="role">(Admin)</span>
          </a-menu-item>
          <a-divider class="my-1" />
          <a-sub-menu v-if="showSettings" key="sub1">
            <template #icon>
              <SettingOutlined class="mr-2" />
            </template>
            <template #title> Settings </template>
            <a-menu-item key="1" @click="PasswordChange">
              <LockTwoTone class="mr-2" />
              Change Password

              <div v-if="PasswordChangeModal === true">
                <div>
                  <PasswordChangeModalVue
                    :open-add-modal="PasswordChangeModal"
                    @close="ModalClose"
                  />
                </div>
              </div>
            </a-menu-item>

            <!-- <a-menu-item key="2" @click="EmailChange">
              <MailOutlined class="mr-2" />
              Change Email

              <div v-if="this.EmailChangeModal === true">
                <div>
                  <EmailChangeModalVue
                    :openAddModal="this.EmailChangeModal"
                    @close="ModalCloseEmail"
                  />
                </div>
              </div>
            </a-menu-item> -->

            <a-menu-item key="2" @click="OTPMethodChange">
              <SafetyCertificateTwoTone class="mr-2" />
              Change MFA Method

              <div v-if="OTPMethodChangeModal === true">
                <div>
                  <OTPMethodChangeModalVue
                    :open-add-modal="OTPMethodChangeModal"
                    @close="ModalCloseOTPMethod"
                  />
                </div>
              </div>
            </a-menu-item>
          </a-sub-menu>
          <a-menu-item
            v-if="showSwitchOrganization"
            key="3"
            @click="() => onLogout(false)"
          >
            <LogoutOutlined class="mr-2" />
            Switch Organization
          </a-menu-item>
          <a-menu-item key="3" @click="() => onLogout(true)">
            <LogoutOutlined class="mr-2" />
            Logout
          </a-menu-item>
        </a-menu>
      </template>
      <a-avatar class="mr-4">
        <template #icon>
          <user-outlined />
        </template>
      </a-avatar>
    </a-dropdown>
  </a-layout-header>
</template>
<script>
import {
  DownOutlined,
  LockTwoTone,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SafetyCertificateTwoTone,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons-vue';
import firebaseProject from 'src/config/firebase-current-project.json';
import { isOrgUrl } from 'src/router/index.js';
import { getAzureLoginStatus } from 'src/utils/auth';
import { reactive, ref } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { roles } from '../../config/roles-config';
import * as configuration from '../../service/http/httpRequestConfig';
import OTPMethodChangeModalVue from '../auth/OTPMethodChangeModal.vue';
import PasswordChangeModalVue from '../auth/PasswordChangeModal.vue';
import InstallerButton from '../shared/Components/InstallerButton';
export default {
  components: {
    PasswordChangeModalVue,
    OTPMethodChangeModalVue,
    LogoutOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    InstallerButton,
    DownOutlined,
    LockTwoTone,
    SettingOutlined,
    SafetyCertificateTwoTone,
  },
  setup() {
    const currentProject = firebaseProject['project-name'];

    const { prodHostUrl, testHostUrl, localHostUrl } = configuration;

    const hostURLOptions = {
      [prodHostUrl]: 'PROD',
      [testHostUrl]: 'DEV',
      [localHostUrl]: 'LOCAL',
    };
    const selectedURL = ref(getInitialValue());
    const fundamentals = 'App Fundamentals';
    const docsRouteName = 'User Manuals';
    const profileRouteName = 'Profile Settings';
    const state = reactive({
      isMobileScreen: false,
    });

    function getInitialValue() {
      return hostURLOptions[localStorage.getItem('hostURL')];
    }

    function handleChangeURL(url) {
      selectedURL.value = hostURLOptions[url];
      localStorage.setItem('hostURL', url);
      window.location.reload();
    }

    return {
      EmailChangeModal: false,
      PasswordChangeModal: false,
      OTPMethodChangeModal: false,
      roles,
      state,
      selectedURL,
      docsRouteName,
      fundamentals,
      currentProject,
      hostURLOptions,
      handleChangeURL,
      profileRouteName,
      showSettings: false,
    };
  },
  computed: {
    ...mapGetters([
      'getSidebarState',
      'currentRoute',
      'user',
      'role',
      'organization',
      'msalInstance',
    ]),
    isOrgUser() {
      return [roles.user, roles.org_user, roles.support_user].includes(
        this.role
      );
    },

    host() {
      return window.location.host;
    },

    showSwitchOrganization() {
      return (
        parseInt(localStorage.getItem('organizationsCount')) > 1 &&
        isOrgUrl(localStorage.getItem('organization'))
      );
    },
  },

  created() {
    this.createMsalInstance();
    this.showSettings = localStorage.getItem('azureLogin') ? false : true;
    var w = window.innerWidth;
    if (w < 768) {
      this.state.isMobileScreen = true;
    }
    window.addEventListener('resize', this.onWindowResize);
  },

  unmounted() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  methods: {
    ...mapActions([
      'updateSidebar',
      'resetState',
      'userLogout',
      'clearState',
      'createMsalInstance',
      'resetDeviceState',
    ]),
    onLogout(logout) {
      this.resetState();
      this.resetDeviceState();
      if (getAzureLoginStatus()) {
        this.AzureSignOut();
      } else {
        this.clearState({ fromBasePage: false, logout });
      }
    },
    onWindowResize() {
      var w = window.innerWidth;
      if (w < 768) {
        this.state.isMobileScreen = true;
        if (!this.getSidebarState) {
          this.updateSidebar(!this.getSidebarState);
        }
      } else {
        this.state.isMobileScreen = false;
        if (this.getSidebarState) {
          this.updateSidebar(!this.getSidebarState);
        }
      }
    },
    toggleNavMenu() {
      const mobileMenu = document.getElementById('mobile-menu');
      if (this.state.isMobileScreen) {
        mobileMenu?.classList.add('show');
      } else {
        this.updateSidebar(!this.getSidebarState);
      }
    },
    PasswordChange() {
      this.PasswordChangeModal = true;
    },
    EmailChange() {
      this.EmailChangeModal = true;
    },
    OTPMethodChange() {
      this.OTPMethodChangeModal = true;
    },
    ModalClose() {
      this.PasswordChangeModal = false;
    },
    ModalCloseEmail() {
      this.EmailChangeModal = false;
    },
    ModalCloseOTPMethod() {
      this.OTPMethodChangeModal = false;
    },
    async AzureSignOut() {
      // const account = this.msalInstance.getActiveAccount();
      // await this.msalInstance.logoutPopup({ account: account });
      this.clearState();
    },
  },
};
</script>
<style scoped>
.trigger {
  font-size: 20px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

header {
  position: relative;
  z-index: 10;
  background: #fff;
  height: 52px;
}

.role {
  font-weight: 700;
  font-size: 13px;
  color: #34495e;
}

.ant-switch-checked {
  background-color: lightgreen !important;
}
</style>
