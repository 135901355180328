<template>
  <div>
    <a-modal
      title="Verify OTP - Authenticator App"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      :mask-closable="false"
      @ok="handleOk"
      @cancel="$emit('close')"
      okText="Verify"
      cancelText="Close"
      class="otp-authentication-modal"
    >
      <div class="qr-code-wrapper">
        <p class="qr-code-instruction">
          Please scan this QR code using your Authenticator app.
        </p>
        <div class="qr-code-container">
          <img
            v-if="QRImage"
            :src="QRImage"
            alt="QR Code"
            class="qr-code-img"
          />
          <a-spin v-else />
        </div>
      </div>

      <div class="otp-instruction">
        <p>
          After scanning the QR code, enter the 6-digit OTP from your
          Authenticator app below.
        </p>
      </div>

      <OTPInput
        @update-otp-value="updateOtpValue"
        :disable-input="!QRImage"
      />
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import AuthService from '@/services/auth';
import OTPInput from './OTPInput.vue';
import { useToast } from 'vue-toastification';

export default defineComponent({
  components: {
    OTPInput,
  },
  data() {
    return {
      otpValue: '',
      confirmLoading: false,
      QRImage: null,
      visible: true,
      toast: null,
      scanQRCodeMessage:
        'Please scan this QR code from your authenticator app.',
    };
  },
  emits: ['close'],
  computed: {
    ...mapGetters(['isGoogleAuthActivated']),
  },
  async mounted() {
    await this.fetchQRCode();
    this.toast = useToast();
    this.toast.success(this.scanQRCodeMessage);
  },
  methods: {
    ...mapActions(['setOTPMethodGoogleAuth']),
    async fetchQRCode() {
      try {
        const response = await AuthService.getQRCode();
        this.QRImage = URL.createObjectURL(
          new Blob([response[1]], { type: 'image/png' })
        );
      } catch (error) {
        console.error('Error fetching QR code:', error);
      }
    },
    async handleOk() {
      this.confirmLoading = true;
      if (
        await this.setOTPMethodGoogleAuth({
          OTP: this.otpValue,
        })
      ) {
        this.visible = false;
      }
      this.confirmLoading = false;
    },
    updateOtpValue(otpValue) {
      this.otpValue = otpValue;
    },
  },
});
</script>

<style scoped>
.qr-code-wrapper {
  text-align: center;
  margin-bottom: 20px;
}

.qr-code-instruction {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.qr-code-img {
  width: 200px;
  height: 200px;
  display: block;
  margin: 0 auto;
}

.otp-instruction {
  text-align: center;
  margin-bottom: 20px;
}
</style>