<template>
  <div>
    <a-modal
      title="Verify OTP - SMS"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      :mask-closable="false"
      @ok="handleOk"
      @cancel="$emit('close')"
      okText="Verify"
      cancelText="Close"
      class="otp-authentication-modal"
      :ok-button-props="{
        disabled: !isOtpSent,
      }"
    >
      <div class="mobile-input-container">
        <vue-tel-input
          class="mobile-input"
          v-model="mobileNumber"
          mode="international"
          :disabled="isOtpSent"
          :onlyCountries="['US', 'CA', 'MX']"
        ></vue-tel-input>

        <a-button
          class="send-otp-button"
          @click="sendOtpWithNumber"
          :disabled="isOtpSent"
          :loading="isLoading"
          >Send OTP</a-button
        >
      </div>

      <div class="otp-instruction">
        <p>Enter the 6-digit OTP sent on your mobile number:</p>
      </div>

      <OTPInput
        @update-otp-value="updateOtpValue"
        :disable-input="!isOtpSent"
      />
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters, mapActions } from 'vuex';
import AuthService from '@/services/auth';
import OTPInput from './OTPInput.vue';
import { useToast } from 'vue-toastification';
import { VueTelInput } from 'vue-tel-input';

export default defineComponent({
  components: {
    OTPInput,
    VueTelInput,
  },
  data() {
    return {
      otpValue: '',
      confirmLoading: false,
      visible: true,
      toast: null,
      mobileNumber: null,
      isOtpSent: false,
      isLoading: false,
    };
  },
  emits: ['close'],
  mounted() {
    this.toast = useToast();
  },
  methods: {
    ...mapActions(['setOTPMethodSms']),
    async sendOtpWithNumber() {
      try {
        if (!this.mobileNumber) {
          this.toast.error('Mobile number can not be empty');
          return;
        }

        this.isLoading = true;
        const [error, data] = await AuthService.sendOTPSms({
          mobile_number: this.mobileNumber,
        });

        if (data) {
          this.isOtpSent = true;
        }
        this.isLoading = false;
      } catch (error) {
        this.isOtpSent = false;
        console.error('Error Sending OTP:', error);
      }
    },
    async handleOk() {
      this.confirmLoading = true;
      if (
        await this.setOTPMethodSms({
          OTP: this.otpValue,
          mobile_number: this.mobileNumber,
        })
      ) {
        this.visible = false;
      }
      this.confirmLoading = false;
    },
    updateOtpValue(otpValue) {
      this.otpValue = otpValue;
    },
  },
});
</script>

<style scoped>
.otp-instruction {
  text-align: center;
  margin-bottom: 10px;
}

.mobile-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.mobile-input {
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;
}

.send-otp-button {
  background-color: #1890ff;
  color: #fff;
  border: none;
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.otp-instruction p {
  font-size: 14px;
  color: #888;
  text-align: center;
  margin-bottom: 20px;
}
</style>
