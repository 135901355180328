<template>
  <div>
    <a-modal
      title="Select MFA Method"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      :mask-closable="false"
      html-type="submit"
      @cancel="$emit('close')"
      footer=""
      width="400px"
      class="otp-authentication-modal"
    >
      <a-form
        layout="vertical"
        :model="form"
        :rules="rules"
        name="OTPSelection"
        class="user-form"
        @finish="onSubmit"
      >
        <div class="form-check">
          <div class="radio-container">
            <input
              class="form-check-input"
              type="radio"
              value="email"
              id="Email"
              v-model="form.otp_method"
              required
            />
            <label class="form-check-label" for="Email">
              <i class="bi bi-envelope email-icon"></i>
              Email
            </label>
          </div>
          <div class="radio-container">
            <input
              class="form-check-input"
              type="radio"
              value="googleAuth"
              id="GoogleAuth"
              v-model="form.otp_method"
              required
            />
            <label class="form-check-label" for="GoogleAuth">
              <i class="bi bi-qr-code qr-icon"></i>
              Authenticator App
            </label>
          </div>
          <div class="radio-container">
            <input
              class="form-check-input"
              type="radio"
              value="sms"
              id="Phone Number"
              v-model="form.otp_method"
              required
            />
            <label class="form-check-label" for="Phone Number">
              <i class="bi bi-phone mobile-icon"></i>
              SMS
            </label>
          </div>
        </div>
        <div class="buttons-w mt-4">
          <a-button
            type="primary"
            size="medium"
            html-type="submit"
            class="btn-block rounded-sm"
          >
            Done
          </a-button>
        </div>
      </a-form>
    </a-modal>
    <div v-if="showOTPModal === true">
      <AuthenticatorAppSetupModal
        v-if="form.otp_method === 'googleAuth'"
        @close="closeOTPModal"
      />
      <SMSSetupModal
        v-else-if="form.otp_method === 'sms'"
        @close="closeOTPModal"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ref } from 'vue';
import OtpModal from './OTPModal.vue';
import AuthenticatorAppSetupModal from './AuthenticatorAppSetupModal.vue';
import SMSSetupModal from './SMSSetupModal.vue';

export default {
  data() {
    return {
      form: {
        otp_method: '',
      },
      showOTPModal: false,
    };
  },
  created() {
    this.form.otp_method = this.receiveOtpOn || 'email';
  },
  emits: ['close'],
  setup() {
    const confirmLoading = ref(false);
    const visible = ref(true);
    const rules = {
      otp_method: [
        {
          required: true,
          trigger: 'submit',
          message: 'Please select an OTP method',
        },
      ],
    };
    return { confirmLoading, visible, rules };
  },
  components: {
    OtpModal,
    AuthenticatorAppSetupModal,
    SMSSetupModal,
  },
  computed: {
    ...mapGetters(['receiveOtpOn']),
  },
  methods: {
    ...mapActions(['setOTPMethod', 'setOTPMethodEmail']),
    async onSubmit() {
      this.$emit('close');
      if (this.form.otp_method === this.receiveOtpOn) {
        this.visible = false;
        return;
      }

      if (this.form.otp_method == 'email') {
        await this.setOTPMethodEmail({
          receive_otp_on: 'email',
        });
      } else {
        this.showOTPModal = true;
      }
      this.visible = false;
      this.emit();
    },
    closeOTPModal() {
      this.visible = false;
      this.showOTPModal = false;
    },
  },
};
</script>

<style scoped>
.user-form {
  width: 100%;
  margin-top: 0;
}

.radio-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.form-check-input {
  margin-right: 12px;
  cursor: pointer;
}

.email-icon,
.mobile-icon,
.qr-icon {
  font-size: 26px;
  color: #1890ff;
  margin-right: 15px;
  margin-left: 5px;
}

.form-check-label {
  font-size: 16px;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.buttons-w {
  margin-top: 20px;
  text-align: center;
}

.btn-block {
  width: 100%;
}
</style>

<style>
.otp-authentication-modal,
.otp-authentication-modal .ant-modal-content,
.otp-authentication-modal .ant-modal-footer .ant-btn {
  overflow: hidden;
  border-radius: 5px;
}

.otp-authentication-modal .ant-modal-header {
  background-color: #1890ff;
}

.otp-authentication-modal .ant-modal-title {
  color: white;
  font-size: 20px;
}

.otp-authentication-modal .ant-modal-close {
  color: white;
}
</style>
